import React, { useState, useEffect, useRef } from "react";
import styles from "./CalculatorBlock.module.scss";
import classNames from "classnames/bind";
import Parser from "expr-eval";
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { getCalculator } from "../../../bootstrap/services/widgetService";

import Filter from "../../containers/Filter";
import Render from "../../containers/Render";
import Input from "../../ui/Input";
import Hint from "../../ui/Hint";

import checkCondition from "../../../helpers/checkCondition";
import compileFormat from "../../../helpers/compileFormat";
import convertTimestamp from "../../../helpers/convertTimestamp";
import yandexMetrika from "../../../helpers/yandexMetrika";
import generateFilter from "../../../helpers/generateFilter";
import Icon from "../../ui/Icon";

const cnb = classNames.bind(styles);

function CalculatorBlock({
  calculatorSlug,
}) {
  const [calculator, setCalculator] = useState({});
  const [loading, setLoading] = useState(true);
  const [filter, setStateFilter] = useState(null);
  const [formula, setFormula] = useState("");
  const [inputValues, setInputValues] = useState({});
  const [result, setResult] = useState("");
  const [errors, setErrors] = useState({});
  const firstDateInput = useRef();
  const secondDateInput = useRef();
  const fields = {};

  useEffect(() => {
    if (calculatorSlug) {
      getCalculator(calculatorSlug)
        .then((res) => {
          if (res?._id) {
            setCalculator(res);
            setStateFilter(generateFilter(res));
            setLoading(false);
          }
        })
        .catch(err => console.log(err));
    }
  }, [calculatorSlug])

  const handleFormula = () => {
    const { formulas } = calculator;

    formulas.map((item) => {
      if (checkCondition(item.condition, filter)) {
        setFormula(item.formula);
      }
    });
  };

  const handleChange = (id, newValue, type) => {
    const selectedId = filter[id];

    if (type === "multiselect") {
      if (selectedId.includes(newValue)) {
        const index = selectedId.indexOf(newValue);

        if (newValue !== -1) selectedId.splice(index, 1);
      } else {
        selectedId.push(newValue);
      }
    } else if (type === "select" || type === "radio") {
      filter[id] = [newValue];
    } else {
      filter[id] = newValue;
    }

    setStateFilter(filter);
    setResult(null);
    handleFormula();
  };

  const handleValidation = () => {
    const validationErrors = {};

    calculator.variables.map((variable) => {
      if (!inputValues[variable.id] && inputValues[variable.id] !== 0) {
        if (variable._type !== "date-range")
          validationErrors[variable.id] =
            "Заполните поле, чтобы получить результат";
        else validationErrors[variable.id] = "Выбран некорректный интервал дат";
      }
      if (
        inputValues[variable.id] &&
        Number.isNaN(Number(inputValues[variable.id])) &&
        variable._type !== "date-range"
      ) {
        validationErrors[variable.id] = "Поле заполнено некорректно";
      }
      if (inputValues[variable.id] < 0 && variable._type === "date-range") {
        validationErrors[variable.id] = "Выбран некорректный интервал дат";
      }
    });

    setErrors(validationErrors);

    setTimeout(() => setErrors({}), 3000);
  };

  const handleSubmit = () => {
    const parser = Parser.Parser;
    let formulaResult = [...formula];
    handleValidation();
    Object.keys(inputValues).map((item) => {
      formula.map((character, i) => {
        if (character === item) formulaResult[i] = inputValues[item];
      });
    });

    if (formulaResult.includes("mx")) {
      const mx = formulaResult.join("").split("mx");

      mx.pop();
      mx.shift();

      let max = mx[0];

      max = max.split("~");
      max.map((el, i) => {
        max[i] = parser.evaluate(el);
      });
      max = Math.max.apply(null, max);

      const indexes = [];

      formulaResult.map((el, i) => {
        if (el === "mx") {
          indexes.push(i);
        }
      });

      for (let i = indexes[0]; i <= indexes[1]; i++) {
        indexes.push(i);
      }

      indexes.shift();
      indexes.shift();

      formulaResult = formulaResult.filter(
        (value, index) => indexes.indexOf(index) === -1
      );
      formulaResult.splice(indexes[0], 0, max);
      formulaResult = parser.evaluate(formulaResult.join(""));
    } else if (formulaResult.includes("mn")) {
      const mn = formulaResult.join("").split("mn");

      mn.pop();
      mn.shift();

      let min = mn[0];

      min = min.split("~");
      min.map((el, i) => {
        min[i] = parser.evaluate(el);
      });
      min = Math.min.apply(null, min);

      const indexes = [];

      formulaResult.map((el, i) => {
        if (el === "mn") {
          indexes.push(i);
        }
      });

      for (let i = indexes[0]; i <= indexes[1]; i++) {
        indexes.push(i);
      }

      indexes.shift();
      indexes.shift();

      formulaResult = formulaResult.filter(
        (value, index) => indexes.indexOf(index) === -1
      );
      formulaResult.splice(indexes[0], 0, min);
      formulaResult = parser.evaluate(formulaResult.join(""));
    } else {
      formulaResult = parser.evaluate(formulaResult.join(""));
    }

    if (!Number.isInteger(formulaResult))
      formulaResult = formulaResult.toFixed(2);

    setResult(formulaResult);
  };

  const checkCanSubmit = (_inputValues) => {
    const filteredVariables = calculator.variables.filter((variable) => formula && formula.includes(variable.id));
    const answersKeys = Object.keys(_inputValues).filter((_key) => formula && formula.includes(_key));
    const answers = Object.values(_inputValues);

    if (
        (answersKeys.length === filteredVariables.length) &&
        !answers.some(answer => !answer)
      ) {
        return true;
      }
    return false;
  };

  const handleInputChange = (e, fieldId) => {
    if (e && typeof e !== "number") inputValues[fieldId] = e.target.value;
    else inputValues[fieldId] = e;
    if (checkCanSubmit(inputValues)) {
      handleSubmit();
    };
    setInputValues(inputValues);

    if ((e && typeof e !== "number" && !e.target.value) || !e) {
      setResult(null);
    }
  };

  const handleDateRange = (id) => {
    const dateOne = new Date(
      firstDateInput.current.input.value.replace(
        /(\d{2}).(\d{2}).(\d{4})/,
        "$2/$1/$3",
      ),
    );
    const dateTwo = new Date(
      secondDateInput.current.input.value.replace(
        /(\d{2}).(\d{2}).(\d{4})/,
        "$2/$1/$3",
      ),
    );

    if (dateOne <= dateTwo) {
      setErrors({});

      handleInputChange(Math.abs(dateTwo - dateOne) / 86400000, id);
    } else if (dateOne > dateTwo) {
      handleInputChange((dateTwo - dateOne) / 86400000, id);
    } else {
      handleInputChange(undefined, id);
    }
  };

  const MONTHS = [
    "январь",
    "февраль",
    "март",
    "апрель",
    "май",
    "июнь",
    "июль",
    "август",
    "сентябрь",
    "октябрь",
    "ноябрь",
    "декабрь",
  ];

  const WEEKDAYS_LONG = [
    "воскресенье",
    "понедельник",
    "вторник",
    "среда",
    "четверг",
    "пятница",
    "суббота",
  ];

  const addUtmInLocalstorage = () => {
    window.localStorage.setItem("utmForm", "blog_calculator");
    const storageEvent = new StorageEvent('storage', {
      key: "utmForm",
      newValue: "blog_calculator",
    });
    window.dispatchEvent(storageEvent);
  };

  const WEEKDAYS_SHORT = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

  const renderResult = (_result) => {
    const summWithServices = Number(_result) + 6290;

    return (
      <>
        <div className={cnb("calculatorBlock__result-badge")}>
          <div className={cnb("calculatorBlock__result-badge__icon")} />
          <div className={cnb("calculatorBlock__result-badge__summ")}>
            {Math.ceil(summWithServices).toLocaleString("ru")} ₽
          </div>
        </div>
        <div className={cnb("calculatorBlock__result-description")}>
          <div className={cnb("calculatorBlock__result-description__title")}>
            В сумму включено
          </div>
          <div className={cnb("calculatorBlock__result-description__price-section")}>
            <div className={cnb("calculatorBlock__result-description__price-title")}>
              Ваши убытки + компенсация
            </div>
            <div className={cnb("calculatorBlock__result-description__price-summ")}>
              {Math.ceil(_result).toLocaleString("ru")} ₽
            </div>
          </div>
          <div className={cnb("calculatorBlock__result-description__price-section")}>
            <div className={cnb("calculatorBlock__result-description__price-title")}>
              Услуги Destralegal.ru
            </div>
            <div className={cnb("calculatorBlock__result-description__price-summ")}>
              6 290 ₽
            </div>
          </div>
        </div>
      </>
    )
  };

  const renderInput = (_type, id, label, hint) => {
    switch (_type) {
      case "input":
        return (
          <>
            <Input
              type="number"
              size="medium"
              onChange={(e) => handleInputChange(e, id)}
              r={(e) => {
                fields[id] = e;
              }}
            />
            <div className={cnb("calculatorBlock__error")}>{errors[id]}</div>
          </>
        );
      case "percent":
        return (
          <>
            <Input
              type="number"
              size="medium"
              onChange={(e) => handleInputChange(e.target.value * 0.01, id)}
              r={(e) => {
                fields[id] = e;
              }}
            />
            <div className={cnb("calculatorBlock__error")}>{errors[id]}</div>
          </>
        );
      case "date-range":
        return (
          <div className={cnb("calculatorBlock__date-range")}>
            <div className={cnb("calculatorBlock__date-range__field", "calculatorBlock__date-range__field--first")}>
              {label.includes("~") && (
                <div className={cnb("calculatorBlock__date-range__field__title")}>
                  {label.split("~")[0]}
                </div>
              )}
              <div className={cnb("calculatorBlock__date-range__field__input")}>
                <DayPickerInput
                  dayPickerProps={{
                    locale: "ru",
                    months: MONTHS,
                    weekdaysLong: WEEKDAYS_LONG,
                    weekdaysShort: WEEKDAYS_SHORT,
                  }}
                  formatDate={(e) => convertTimestamp(e)}
                  placeholder="ДД.ММ.ГГГГ"
                  onDayChange={() => handleDateRange(id)}
                  ref={firstDateInput}
                />
              </div>
            </div>
            <div className={cnb("calculatorBlock__date-range__field", "calculatorBlock__date-range__field--second")}>
              {label.includes("~") && (
                <div className={cnb("calculatorBlock__date-range__field__title")}>
                  {label.split("~")[1]}
                  {label.includes("~") && hint && (
                    <div className={cnb("calculatorBlock__item-hint")}>
                      <Hint template="control">
                        <Render element={{ value: hint }} />
                      </Hint>
                    </div>
                  )}
                </div>
              )}

              <div className={cnb("calculatorBlock__date-range__field__input", "calculatorBlock__date-range__field__input--transform")}>
                <DayPickerInput
                  dayPickerProps={{
                    locale: "ru",
                    months: MONTHS,
                    weekdaysLong: WEEKDAYS_LONG,
                    weekdaysShort: WEEKDAYS_SHORT,
                  }}
                  formatDate={(e) => convertTimestamp(e)}
                  placeholder="ДД.ММ.ГГГГ"
                  onDayChange={() => handleDateRange(id)}
                  ref={secondDateInput}
                />
              </div>
            </div>

            <div className={cnb("calculatorBlock__error", "calculatorBlock__date-range__error")}>{errors[id]}</div>
          </div>
        );
      default:
    }
  };

  useEffect(() => {
    if (Object.keys(calculator).length > 0) {
      handleFormula();
    }
  }, [filter]);

  useEffect(() => {
    if (Object.keys(calculator).length > 0) {
      if (formula && !calculator.variables.some((variable) => formula.includes(variable.id))) {
        handleSubmit();
      };
    }
  }, [formula]);

  if (loading) {
    return <div/>
  }

  return (
    <div className={cnb("calculator-instruction-block")}>
      <div className={cnb("calculatorBlock")}>
        {calculator.values && calculator.values.length > 0 && (
          <div className={cnb("calculatorBlock__section", "calculatorBlock__section-filter")}>
            <div className={cnb("calculatorBlock__filter")}>
              <div className={cnb("calculatorBlock__filter__section", "calculatorBlock__filter__section-fields")}>
                <Filter
                  values={calculator.values}
                  handleChange={handleChange}
                  filter={filter}
                />
              </div>
            </div>
          </div>
        )}
        <div className={cnb("calculatorBlock__section", "calculatorBlock__section-content")}>
          <div className={cnb("calculatorBlock__content")}>
            <div className={cnb("calculatorBlock__content__section", "calculatorBlock__content__section-fields")}>
              <div className={cnb("calculatorBlock__fields")}>
                <div className={cnb("calculatorBlock__fields__section", "calculatorBlock__fields__section-items")}>
                  {calculator.variables.map(
                    (variable) =>
                      formula &&
                      formula.includes(variable.id) && (
                        <div
                          className={cnb("calculatorBlock__item")}
                          key={`${variable.id}__calculator__items`}
                        >
                          <div className={cnb("calculatorBlock__item__section", "calculatorBlock__item__section-title")}>
                            {!variable.label.includes("~") && (
                              <div className={cnb("calculatorBlock__item__title")}>
                                {variable.label}
                              </div>
                            )}
                            {!variable.label.includes("~") && !!variable.hint && (
                              <div className={cnb("calculatorBlock__item-hint")}>
                                <Hint template="control">
                                  <Render
                                    element={{
                                      value: compileFormat(variable.hint),
                                    }}
                                  />
                                </Hint>
                              </div>
                            )}
                          </div>
                          <div className={cnb("calculatorBlock__item__section", "calculatorBlock__item__section-input")}>
                            {renderInput(
                              variable._type,
                              variable.id,
                              variable.label,
                              variable.hint,
                            )}
                          </div>
                        </div>
                      ),
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cnb("calculatorBlock__result-section")}>
        <div className={cnb("calculatorBlock__result__content")}>
          <div className={cnb("calculatorBlock__result__content-title")}>
            Вы можете вернуть
          </div>
          {(result && result > 0) ?
            renderResult(result)
            :
            <div className={cnb("calculatorBlock__result__content-summ")}>
              {result < 0 ? '0 ₽' : '- ₽'}
            </div>
          }
        </div>
        {result
          ?
            <div
              className={cnb("calculatorBlock__result__button")}
              onClick={() => {
                yandexMetrika("reachGoal", "calculator_started");
                addUtmInLocalstorage();
              }}
            >
              Оставить заявку
            </div>
          :
            <div
              className={cnb("calculatorBlock__result__button")}
              onClick={() => {
                handleValidation();
              }}
            >
              <div className={cnb("calculatorBlock__result__button__icon")}>
                <Icon name='calculator' color='#ffffff'/>
              </div>
                Рассчитать
            </div>
        }
      </div>
    </div>
  );
}
export default CalculatorBlock;

import styles from "./Hint.module.css";

import React from "react";

const Hint = ({ children, template = "default", title, CustomIcon, labelClass }) => {
  const contentRef = React.useRef();

  const handleOver = () => {
    contentRef.current.classList.remove(styles.top);

    const rect = contentRef.current.getBoundingClientRect();

    if (rect.right + 10 >= window.innerWidth) {
      contentRef.current.style.position = "absolute";
      if (450 > window.innerWidth) {
        contentRef.current.style.left = 0;
      } else {
        contentRef.current.style.left = `-${rect.width / 2}px`;
      }
      if (contentRef.current) contentRef.current.style.opacity = "1";
    } else if (rect.bottom >= window.innerHeight) {
      contentRef.current.classList.add(styles.top);
    }
  };

  return (
    <span
      className={`${styles.component} ${template ? styles[template] : ""}`}
      onMouseOver={handleOver}
    >
      {CustomIcon ?? (
        <>
          <span className={`${styles.section} ${styles.sectionIcon}`}>
            <span className={`${styles.icon} ${template ? styles[`icon--${template}`] : ""}`} />
          </span>
          {title && (
            <span className={`${styles.section} ${styles.sectionTitle} ${labelClass}`}>
              <span className={styles.title}>{title}</span>
            </span>
          )}
        </>
      )}
      <span className={`${styles.section} ${styles.sectionContent}`} ref={contentRef}>
        <div className={styles.content}>{children}</div>
      </span>
    </span>
  );
};
export default Hint;

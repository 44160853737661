const convertTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const yyyy = date.getFullYear();
  const mm = month < 10 ? `0${month}` : month;
  const dd = day < 10 ? `0${day}` : day;

  return `${dd}.${mm}.${yyyy}`;
};

export default convertTimestamp;

import './variables.css';
import "react-day-picker/lib/style.css";
import React from 'react';
import isWindowDefined from './helpers/isWindowDefined';
import CalculatorBlock from './components/widgets/CalculatorBlock';

const App = ({injectTo}) => {
  const calculatorId = isWindowDefined() 
    && window.destraParams 
    && window.destraParams.instructionId 
  ? window.destraParams.instructionId 
  : injectTo.getAttribute("calculator-id");

  return <CalculatorBlock calculatorSlug={calculatorId} />
}
export default App;

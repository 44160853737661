
import axios from 'axios';
import decrypt from '../../helpers/decrypt';

export const resultDecrypt = (data, encrypted = true) => {
  if (encrypted) {
    return decrypt(data.result);
  }
  return data.result;
};

export default axios.create();
